globalThis["__sentryRewritesTunnelPath__"] = undefined;
globalThis["SENTRY_RELEASE"] = {"id":"695e3b9aefcfd50a95cda9a5986cf3f42de07594"};
globalThis["__sentryBasePath"] = undefined;
globalThis["__rewriteFramesAssetPrefixPath__"] = "";
import * as Sentry from '@sentry/nextjs'

Sentry.init({
  dsn: 'https://0345d0cacda34590b6139082a4521911@o1192929.ingest.us.sentry.io/6314703',
  tracesSampleRate: 1,
  debug: false,
  maxValueLength: 500,
})
